var countPageView = (function($) {
	function updateCounter($pageId) {
        $.ajax({
            url: Update_page_view.url,
            data: {
                'action':'updatePageView',
                'id': parseInt($pageId)
            },
            success:function(data) {},
            error: function(errorThrown) {}
        });
	}

	return {
		init: function() {
			var $pageId = $('.fn-get-singel-post').attr('data-page-id');

            console.log($pageId);

			updateCounter($pageId);
		}
	};
})(jQuery);
