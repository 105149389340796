var selecter = (function($) {
    var formContainer = $('.fn-form-container');
    var loader = $('.fn-form-loader');
    var optionTrigger = $('.fn-trigger-form');

    /**
     * getForm - Load Gravity forms
     * @param  {[int]} $form_id - Gravity forms ID.
     * @return {[HTML]} - HTML of form.
     */
    
    var getForm = function($form_id) {
        formContainer.css('display', 'none');
        loader.css('display', 'block');

        $.ajax({
            url: Load_gravity_form_endpoint.url,
            data: {
                'action':'mm_load_gravity_form',
                'id': parseInt($form_id)
            },
            success:function(data) {
                loader.css('display', 'none');
                formContainer.css('display', 'block');                
                formContainer.empty();
                formContainer.append(data);

                $('body').trigger('attempt-form-autofill');

                if ($('.charleft.ginput_counter')) {
                    $('.charleft.ginput_counter').css('display', 'none');
                    $('.charleft.ginput_counter').first().css('display', 'block');
                }
            },
            error: function(errorThrown) {
                console.log(errorThrown);
            }
        });
    };  

    /**
     * select - Custom selecter
     */
    
    var select = function() {
        $('.fn-custom-select').each(function() {
            var $this = $(this),
                numberOfOptions = $(this).children('option').length,
                isLinked = $(this).attr('data-select-href');

            $this.addClass('select-hidden'); 
            $this.wrap('<div class="custom-select"></div>');
            $this.after('<div class="select-styled"></div>');

            var $styledSelect = $this.next('div.select-styled');
                $styledSelect.text($this.children('option').eq(0).text());

            var $list = $('<ul />', {
                'class': 'select-options'
            }).insertAfter($styledSelect);

            for (var i = 0; i < numberOfOptions; i++) {
                $('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);

                if (isLinked === 'false') {                    
                    if ($('.fn-custom-select').find(':selected').text() === $this.children('option').eq(i).text()) {
                        $styledSelect.text($this.children('option').eq(i).text());
                        getForm($this.children('option').eq(i).val());
                    }
                }
            }

            var $listItems = $list.children('li');

            $styledSelect.click(function(e) {
                e.stopPropagation();
                
                $('div.select-styled.active').not(this).each(function() {
                    $(this).removeClass('active').next('ul.select-options').hide();
                });
                
                $(this).toggleClass('active').next('ul.select-options').toggle();
            });

            $listItems.click(function(e) {
                e.stopPropagation();
                
                $styledSelect.text($(this).text()).removeClass('active');
                $this.val($(this).attr('rel'));
                $list.hide();

                if (isLinked === 'true') {
                    if ($(this).attr('rel') !== '') {
                        window.location.href = $(this).attr('rel');
                    }   
                } else {
                    getForm($(this).attr('rel'));
                }        
            });

            $(document).click(function() {
                $styledSelect.removeClass('active');
                $list.hide();
            });
        });
    };


    return {
        init: function() {
            select();
        }
    };
})(jQuery);