var slider = (function($) {

    function bindSlider(slider) {

        var $slider = $(slider);
        var slides = $slider.find('.fn-slider-image');
        var thumbs = $slider.find('.fn-slider-thumb');
        var currentSlide = 0;

        function handleSlide(activeIndex) {
            $slider.find('.fn-slider-image').removeClass('is-active').removeClass('is-previous');
            $slider.find('.fn-slider-thumb').removeClass('is-active');
            //$('.fn-slider-thumb[data-index="'+ nextSlide +'"]').addClass('active');
            var $newActiveSlide = $(slides[activeIndex]);
            var $newActiveThumb = $(thumbs[activeIndex]);
            $newActiveSlide.addClass('is-active');
            $newActiveThumb.addClass('is-active');

            slides.each(function(i, e) {
                // Set slides behind active slide as previous to animate in from correct way
                if (i < currentSlide) {
                    $(e).addClass('is-previous');
                } else {
                    return;
                }
            });
        }

        handleSlide(currentSlide);

        $slider.find('.fn-slider-prev').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            currentSlide = currentSlide - 1;
            if (currentSlide < 0) {
                currentSlide = 0;
            }
            handleSlide(currentSlide);
        });

        $slider.find('.fn-slider-next').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            currentSlide = currentSlide + 1;
            if (currentSlide > slides.length - 1) {
                currentSlide = slides.length - 1;
            }
            handleSlide(currentSlide);
        });
    }

    return {
        init: function() {
            $.each($('.fn-slider'), function(key, slider) {
                bindSlider(slider);
            });
        }
    };
})(jQuery);