var getPostsBasedOnViews = (function($) {
	function getPosts($postType, $limit, $term) {
        $('.loading-faqs').css('display', 'flex');

        $.ajax({
            url: Get_posts_by_views.url,
            data: {
                'action':'get_posts_by_views',
                'postType': $postType,
                'limit': parseInt($limit),
                'term': $term
            },
            success:function(data) {
                var results = JSON.parse(data);
                var $ulList = $('.fn-most-viewed');

                if (results.length > 0) {
                    $('.loading-faqs').css('display', 'none');
                	$ulList.empty();

                	$.each(results, function(index, item) {
                		var content = '<li class="list__list__item">' +
                					'<a class="list__list__item__link" href="' + item.url + '">' +
										'<div class="list__list__item__link__txt">' + item.title + '</div>' +
									'</a>' +
								'</li>';

                		$ulList.append(content);
                	});
                }
            },
            error: function(errorThrown) {
                console.log(errorThrown);
            }
        });
	}

	return {
		init: function() {
            $.each($('.fn-list'), function(key, list) {
                if ($(list).attr('data-order') === 'views') {
                    var $postType = $(list).attr('data-post-type');
                    var $limit = $(list).attr('data-post-limit');
                    var $term = $(list).attr('data-term');

                    getPosts($postType, $limit, $term);
                }
            });
		}
	};
})(jQuery);
