/**
 * AutoComplete plugin for wordpress search with relevanssi.
 * Author: Johan Johansson
 */

var autoComplete = (function ($) {
    var renderItemOverride = function (ul, item) {
        var re = new RegExp("^" + this.term);
        var t = item.label.replace(
            re,
            '<span class="searcher__auto__list__item__link--highlight">' +
                this.term +
                "</span>"
        );

        return $('<li class="searcher__auto__list__item"></li>')
            .data("item.ui-autocomplete", item)
            .append(
                '<a class="searcher__auto__list__item__link"><span class="name">' +
                    t +
                    '</span><span class="type"></span></a>'
            )
            .appendTo(ul);
    };

    var ajaxSearch = function () {
        var searchInput = $("#s");
        var acs_action = "mm_autocompletesearch";
        var resultContainer = ".fn-auto-complete";
        var queryAllPostTypes = null;
        if (
            searchInput &&
            searchInput[0] &&
            searchInput[0].getAttribute("data-query")
        ) {
            queryAllPostTypes = searchInput[0].getAttribute("data-query");
        }

        var searchUrl =
            Auto_comp_searcher_endpoint.url +
            "?callback=?&action=" +
            acs_action;

        if (queryAllPostTypes && queryAllPostTypes === "all") {
            searchUrl += "&queryAny=true";
        }

        setTimeout(function () {
            $(".ui-autocomplete").addClass("searcher__auto__list");
        }, 1000);

        if (searchInput.length > 0) {
            searchInput
                .autocomplete({
                    minLength: 3,
                    highlight: true,
                    autoFocus: false,
                    appendTo: resultContainer,
                    source: function (req, response) {
                        $.getJSON(searchUrl, req, response);
                    },
                    select: function (event, ui) {
                        // Rediract user to selected search result.
                        window.location.href = ui.item.link;
                    },
                    focus: function (event, ui) {
                        if (ui.item.link !== undefined) {
                            // Remove all selected
                            $(
                                ".searcher__auto__list__item__selected a"
                            ).removeClass(
                                "searcher__auto__list__item__selected__link"
                            );
                            $(".searcher__auto__list__item").removeClass(
                                "searcher__auto__list__item__selected"
                            );

                            // Append select class on the selected item
                            $(".searcher__auto__list__item .ui-state-active")
                                .parent()
                                .addClass(
                                    "searcher__auto__list__item__selected"
                                );

                            $(
                                ".searcher__auto__list__item__selected a"
                            ).addClass(
                                "searcher__auto__list__item__selected__link"
                            );
                        }
                    },
                    response: function (event, ui) {
                        if (!ui.content.length) {
                            $(".ui-autocomplete").css("display", "none");
                        } else {
                            $("#message").empty();
                        }
                    },
                    open: function () {
                        $(".ui-autocomplete").css("width", "100%");
                        $(".ui-autocomplete").css("left", "0");
                        $(".ui-autocomplete").css("top", "15px");
                    },
                })
                .data("ui-autocomplete")._renderItem = renderItemOverride;

            $(resultContainer + "ul").mouseleave(function () {
                $(this).children("li.result").removeClass("selected");
            });
        }
    };

    return {
        init: function () {
            ajaxSearch();
        },
    };
})(jQuery);
