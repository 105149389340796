var header = (function($) {
	var setActiveH = function(active) {
		var wrap = $('.fn-wrap');
		var header = $('.fn-header');
		var mainNav = $('.fn-main-nav');
		var mainNavLinks = mainNav.find('.main-navigation__list__item__link');

		if (active === true) {
			if ($(window).scrollTop() <= 0) {
				wrap.addClass('header-active');
			}

			header.addClass('active');

			$('.main-header__wrap').addClass('active');
			$('.main-header--inner').addClass('active');

			mainNav.addClass('main-navigation--active');

			if (window.innerWidth > 768) {
				var setNewH = header.height() + mainNav.height();
				header.css('height', setNewH);
			}
		} else {
			wrap.removeClass('header-active');
			header.removeClass('active');
			$('.main-header__wrap').removeClass('active');
			$('.main-header--inner').removeClass('active');
			mainNav.removeClass('main-navigation--active');
			header.removeAttr('style');
		}
	};

	var toggleMenu = function() {
		var button = $('.fn-toggle-menu');

		button.on('click', function (event) {
			var self = $(this);

			if (self.hasClass('open')) {
				self.removeClass('open');
				setActiveH(false);
			} else {
				self.addClass('open');
				setActiveH(true);
			}
		});
	};

	var scroller = function() {
		var didScroll;
		var lastScrollTop = 0;
		var delta = 5;
		var headerHeight = $('.fn-header').outerHeight();

		function hasScrolled() {
			var st = $(this).scrollTop();

			// Make sure they scroll more than delta
			if (Math.abs(lastScrollTop - st) <= delta) {
				return;
			}

			// If they scrolled down and are past the navbar, add class .nav-up.
			// This is necessary so you never see what is "behind" the navbar.
			if (st > lastScrollTop && st > headerHeight) {
				// Scroll Down

				if (!$('.fn-header').hasClass('active')) {
					$('.fn-header').removeClass('main-header--down').addClass('main-header--up');
					$('.fn-toggle-menu').removeClass('open');
				}

				if ($('.fn-create-account')) {
					$('.fn-create-account').popover('hide');
				}

				if ($('.fn-profile-popover')) {
					$('.fn-profile-popover').popover('hide');
				}
			} else {
				// Scroll Up
				if (st + $(window).height() < $(document).height()) {
					if (!$('.fn-header').hasClass('active')) {
						$('.fn-header').removeClass('main-header--up').addClass('main-header--down');
						$('.fn-toggle-menu').removeClass('open');
					}
				}
			}

			lastScrollTop = st;
		}

		$(window).scroll(function(event) {
			didScroll = true;
		});

		setInterval(function() {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 250);
	};

	var activeMainLink = function(activatedLink) {
		var mainNavLinks = $('.fn-main-nav').find('.main-navigation__list__item__link');
		var match = (typeof activatedLink !== 'undefined' ? activatedLink : window.location.href).replace(/\/$/, "");

		for (var i = 0; i < mainNavLinks.length; i++) {
			var link = mainNavLinks[i];
			if (link.href.replace(/\/$/, "") === match) {
				link.parentElement.className = 'main-navigation__list__item active';
			} else {
				link.parentElement.className = 'main-navigation__list__item';
			}
		}
	};

	return {
		init: function() {
			toggleMenu();
			scroller();
			activeMainLink();

			var newAccountUrl;

			if ($('.fn-create-account button').attr('data-account-link') !== undefined) {
				newAccountUrl = $('.fn-create-account button').attr('data-account-link');
			} else {
				newAccountUrl = '#';
			}

			$(document).ready(function() {

				function toggleActive(elem, value) {
					if (value) {
						elem.addClass('is-active');
					} else {
						elem.removeClass('is-active');
					}
				}

				var htmlSignup = '<div class="buttons-wrap">' +
					'<div class="buttons-wrap__button">' +
						'<a href="" class="buttons-wrap__button__link btn  fn-active-account">Aktivera konto</a>' +
						'<span class="buttons-wrap__button__txt">För dig som är prenumerant utan konto</span>' +
					'</div>' +
					'<div class="buttons-wrap__button">' +
						'<a href="' + newAccountUrl + '" class="buttons-wrap__button__link btn ">Bli kund</a>' +
						'<span class="buttons-wrap__button__txt">För dig som inte är kund</span>' +
					'</div>' +
					'</div>';

				var htmlPage = '<div class="buttons-wrap">' +
					'<div class="buttons-wrap__button">' +
						'<a href="https://nwt.portal.worldoftulo.com/Account/MyAccount" class="buttons-wrap__button__link btn ">Mitt konto</a>' +
					'</div>' +
					'<div class="buttons-wrap__button">' +
						'<a href="#" class="buttons-wrap__button__link btn  fn-signout">Logga ut</a>' +
					'</div>' +
					'</div>';

				var search = $('input[type=search]');

				if (search.length > 0) {
					search.on('focus blur', function() {
						var $this = $(this),
							value = this.value;

						toggleActive($this, value);
					});

					var searchValue = search.val();

					if (searchValue.length > 0) {
						toggleActive(search, searchValue);
					}
				}

				$('.fn-create-account').each(function(i, obj) {
					$(this).popover({
						html: true,
						delay: 100,
						placement: 'bottom',
						content: function() {
							return $(htmlSignup);
						}
					});
				});

				$('.fn-profile-popover').each(function(i, obj) {
					$(this).popover({
						html: true,
						delay: 100,
						placement: 'bottom',
						content: function() {
							return $(htmlPage);
						}
					});
				});

				$('.fn-scrollTop').on('click', function(e) {
					e.preventDefault();
					window.scrollTo({
						top: 0,
						behavior: "smooth"
					});
				});

				$('.main-navigation__list__item__link').on('click', function() {
					activeMainLink(this.href);
				});
			});
		}
	};
})(jQuery);